import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import theme from "../../theme-client";
import MapBox from "../map-box";
import AssetsModalMobile from "./assets-modal-mobile";

const useStyles = makeStyles((th) => ({
  assets: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
    backgroundColor: th.palette.info.main,
    paddingTop: th.spacing(4),
  },
  titles: {
    [th.breakpoints.down("xs")]: {
      minHeight: "auto",
      paddingLeft: th.spacing(2),
      paddingRight: th.spacing(2),
    },
  },
  popupAsset: { cursor: "pointer", textTransform: "initial", textDecoration: "underline" },
}));

function Assets({ assets = [] }) {
  const classes = useStyles({ ...theme });
  const [open, setOpen] = useState(false);

  return (
    <>
      <AssetsModalMobile assets={assets} open={open} onClose={() => setOpen(false)} />
      <Box className={classes.assets}>
        <Box mt={0} className={classes.titles}>
          <Hidden mdUp>
            <Box display="flex" flexDirection="column" color="text.primary" mb={2} gridGap={16}>
              <Typography variant="h1">
                <Trans>ENCUENTRA EL ESPACIO QUE NECESITAS</Trans>
              </Typography>

              <Typography variant="h3" style={{ fontSize: 17 }}>
                <Trans>ALQUILA ESPACIOS A CORTO PLAZO EN NUESTROS CENTROS</Trans>
              </Typography>
            </Box>
          </Hidden>

          <Box mt={2} mb={1} textAlign="center">
            <Hidden smDown>
              <Box color="text.primary" mb={1}>
                <Typography variant="h3">
                  <Trans>ALQUILA ESPACIOS A CORTO PLAZO EN NUESTROS CENTROS</Trans>
                </Typography>
              </Box>

              <Typography variant="body1" style={{ textTransform: "initial", fontSize: [12, 18] }}>
                <Trans>
                  Te ofrecemos el espacio perfecto para tus ideas. Escoge el centro comercial que prefieras.
                </Trans>
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="body1" style={{ textTransform: "initial", fontSize: [12, 18] }}>
                <Trans>Te ofrecemos el espacio perfecto para tus ideas.</Trans>
              </Typography>
              <Box>
                <Typography
                  onClick={() => setOpen(true)}
                  variant="body1"
                  className={classes.popupAsset}
                  color="primary"
                >
                  <Trans>Escoge el centro comercial que prefieras.</Trans>
                </Typography>
              </Box>
            </Hidden>
          </Box>
        </Box>
        <MapBox assets={assets} />
      </Box>
    </>
  );
}

export default Assets;
