import React, { useEffect, useState } from "react";
import ExpiredSessionComponent from "../../components/expired-session";
import { useNavigate } from "/src/hooks/navigation";

function ExpiredSession() {
  const { navigate } = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("disconectedByInactivity")) {
      setOpen(true);
      sessionStorage.removeItem("disconectedByInactivity");
    }
  }, []);

  const goToSignInPage = () => {
    setOpen(false);
    navigate("/signin");
  };

  return (
    <ExpiredSessionComponent
      open={open}
      onClose={() => setOpen(false)}
      onAccept={goToSignInPage}
    />
  );
}

export default ExpiredSession;
