import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Trans } from "@lingui/macro";
import { isMobile } from "../../utils";
import OwnerModal from "../owner-modal";
import { useNavigate } from "../../hooks/navigation";
import SecondaryButton from "../secondary-button";

export default ({ open, onClose = () => {}, onAccept = () => {} }) => {
  const { navigate } = useNavigate();
  const handleClose = () => {
    navigate(`/`);
    onClose();
  };
  return (
    <OwnerModal
      width={340}
      open={open}
      onClose={handleClose}
      title={<Trans>Tu sesión ha expirado</Trans>}
      hasDivider={false}
      fullScreen={isMobile()}
    >
      <Box mt={0.5}>
        <Typography variant="body1">
          <Trans>Hemos finalizado tu sesión por inactividad. En caso que lo necesites, puedes volver a ingresar.</Trans>
        </Typography>
      </Box>
      <Box textAlign="right" mt={3} mb={2}>
        <SecondaryButton onClick={handleClose}>
          <Trans>Volver</Trans>
        </SecondaryButton>
        <Button size="large" variant="contained" color="primary" onClick={onAccept}>
          <Trans>INICIAR SESIÓN</Trans>
        </Button>
      </Box>
    </OwnerModal>
  );
};
