import React, { useState } from "react";
import { Box, Typography, Button, Container } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

import CookiesInfo from "../../containers/cookies/cookies-info";

const useStyles = makeStyles((th) => ({
  popup: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    backgroundColor: th.palette.white,
    bottom: 0,
    zIndex: 99999,
    width: "100vw",
    height: "105px",
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  moreInfo: {
    marginLeft: theme.spacing(1 / 2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));

const CookiesPopup = ({ open, onClose }) => {
  const classes = useStyles(theme);

  const [showCookiesInfo, setShowCookiesInfo] = useState(false);

  const hadleShowCookiesInfo = () => {
    onClose();
    setShowCookiesInfo(true);
  };

  if (showCookiesInfo) {
    return <CookiesInfo open={showCookiesInfo} onClose={() => setShowCookiesInfo(false)} />;
  }

  if (open) {
    return (
      <Box className={classes.popup}>
        <Container>
          <Box className={classes.container}>
            <Box className={classes.info}>
              <Typography variant="body1">
                <Trans>Al navegar este sitio aceptas las cookies que utilizamos para mejorar tu experiencia.</Trans>{" "}
                <Box
                  component="span"
                  onClick={() => hadleShowCookiesInfo()}
                  style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                >
                  <Trans>Más información</Trans>
                </Box>
              </Typography>
            </Box>
            <Box ml={3}>
              <Button onClick={onClose} variant="contained" color="primary">
                <Trans>Aceptar</Trans>
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }

  return null;
};

export default CookiesPopup;
