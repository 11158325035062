import React, { useState, useEffect } from "react";
import CookiesPopupComponent from "../../components/cookies/cookies-popup";
import { dateDiff } from "../../utils/dateUtils";

const CookiesModal = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const lastCheck = localStorage.getItem("lastCookiesCheck");
    if (lastCheck && Math.abs(dateDiff(Date(), lastCheck)) > 6) {
      setOpen(true);
      localStorage.setItem("lastCookiesCheck", Date());
    }
    if (!lastCheck) {
      localStorage.setItem("lastCookiesCheck", Date());
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return <CookiesPopupComponent open={open} onClose={handleClose} />;
};

export default CookiesModal;
