import slugify from "@sindresorhus/slugify";

const filterByCity = (assetList) => {
  let cities = {};
  assetList.forEach((asset) => {
    if (!cities[asset.city.name]) {
      cities[asset.city.name] = [
        {
          name: asset.name,
          asset_id: asset.asset_id,
          thumb_url: asset.thumb_url,
          coordinates: [asset.longitude, asset.latitude],
        },
      ];
    } else {
      cities[asset.city.name].push({
        name: asset.name,
        asset_id: asset.asset_id,
        thumb_url: asset.thumb_url,
        coordinates: [asset.longitude, asset.latitude],
      });
    }
  });
  return cities;
};

const getAssetSlugMap = (city, assetName) => (city && assetName ? `${slugify(city)}-${slugify(assetName)}/` : "");

const markerHeight = 25;
const markerRadius = 10;
const linearOffset = 25;
const popupOffsets = {
  top: [0, 0],
  "top-left": [0, 0],
  "top-right": [0, 0],
  bottom: [0, -markerHeight],
  "bottom-left": [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
  "bottom-right": [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
  left: [markerRadius, (markerHeight - markerRadius) * -1],
  right: [-markerRadius, (markerHeight - markerRadius) * -1],
};

export { filterByCity, getAssetSlugMap, popupOffsets };
