import React from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import theme from "../../theme-client";
import TelescopeIcon from "../../icons/telescope-icon";
import LampIcon from "../../icons/lamp-icon2";
import MoneyIcon from "../../icons/money-icon";

const useStyles = makeStyles((th) => ({
  features: {
    display: "flex",
    flexDirection: "column",
    height: "380px",
    width: "100%",
    backgroundColor: th.palette.white,
    paddingTop: th.spacing(5),
    alignItems: "center",
    [th.breakpoints.down("xs")]: {
      height: "100%",
      paddingLeft: th.spacing(2),
      paddingRight: th.spacing(2),
    },
  },
  featuresTitle: {
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  iconCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "64px",
    height: "64px",
  },
  contentCard: {
    paddingTop: th.spacing(2),
    display: "flex",
    flexDirection: "column",
    width: "210px",
    alignItems: "center",
    [th.breakpoints.down("xs")]: {
      marginBottom: th.spacing(2),
    },
  },
  carousel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
}));

const FeatureCard = ({ cardIcon, title = "", description = "" }) => {
  const classes = useStyles(theme);
  return (
    <Box className={classes.contentCard}>
      <Box className={classes.iconCard}>{cardIcon}</Box>
      <Box mt={3}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body1" style={{ textAlign: "center" }}>
          <Trans>{description}</Trans>
        </Typography>
      </Box>
    </Box>
  );
};

const FeaturesCards = () => {
  const { i18n } = useLingui();
  const flagPaymentColumn = useSelector((state) => state.misc.featureFlags["flag.perm.payment_column"]);

  return (
    <>
      <FeatureCard
        cardIcon={<TelescopeIcon fill={theme.palette.primary.main} />}
        title={i18n._(t`BUSCA`)}
        description={i18n._(t`Contamos con cientos de locales disponibles en nuestros centros comerciales.`)}
      />
      <FeatureCard
        cardIcon={<LampIcon fill={theme.palette.primary.main} />}
        title={i18n._(t`RESUELVE DUDAS`)}
        description={i18n._(
          t`Solicita toda la información que necesites para tomar tu decisión o coordinar una visita.`,
        )}
      />
      {flagPaymentColumn ? (
        <FeatureCard
          cardIcon={<MoneyIcon fill={theme.palette.primary.main} style={{ width: 96, height: 96 }} />}
          title={i18n._(t`FIRMA Y PAGA`)}
          description={i18n._(t`Firma el contrato y paga de forma online, simple y segura.`)}
        />
      ) : (
        <FeatureCard
          cardIcon={<MoneyIcon fill={theme.palette.primary.main} style={{ width: 96, height: 96 }} />}
          title={i18n._(t`FIRMA Y RESERVA`)}
          description={i18n._(t`Firma el contrato y reserva de forma online, simple y segura.`)}
        />
      )}
    </>
  );
};

function Features() {
  const classes = useStyles(theme);

  return (
    <Box className={classes.features}>
      <Box className={classes.content} width={["100%", "1150px"]}>
        <Box className={classes.featuresTitle} mb={[0, 5]} color="text.primary">
          <Typography variant="h3">
            <Trans>TAN FÁCIL COMO RESERVAR UNA HABITACIÓN DE HOTEL</Trans>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="space-evenly"
          alignItems="center"
          pb={[4, 1]}
          bgcolor={theme.palette.white}
        >
          <FeaturesCards />
        </Box>
      </Box>
    </Box>
  );
}

export default Features;
