import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Image from "material-ui-image";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import theme from "../../theme";
import CloseIcon from "../../icons/close-icon";
import { getAssetSlug } from "../../utils";
import Link from "../link";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: th.spacing(4),
    paddingLeft: th.spacing(3),
    paddingRight: 0,
    paddingTop: th.spacing(0),
    paddingBottom: th.spacing(1),
    maxHeight: "calc(100% - 88px)",
  },
  title: {
    marginBottom: th.spacing(1.5),
    display: "flex",
    flexDirection: "column",
  },
  closeIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: th.spacing(1.5),
    paddingRight: th.spacing(1.75),
  },
  assets: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  backBtn: {
    height: "59px",
    display: "flex",
    position: "absolute",
    justifyContent: "flex-end",
    bottom: 0,
    left: 0,
    width: "100vw",
    paddingLeft: th.spacing(3),
    paddingRight: th.spacing(3),
    alignItems: "center",
    boxShadow: "0px -4px 11px rgba(0, 0, 0, 0.25)",
  },
}));

const AssetsModalMobile = ({ open, onClose, assets = [] }) => {
  const classes = useStyles(theme);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{
        style: {
          maxWidth: "100%",
          padding: 0,
        },
      }}
    >
      <Box className={classes.closeIcon}>
        <CloseIcon
          width={20}
          height={20}
          onClick={onClose}
          style={{
            cursor: "pointer",
          }}
        />
      </Box>
      <Box className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h1">
            <Trans>Escoge el centro comercial que prefieras.</Trans>
          </Typography>
          <Box mt={1}>
            <Box className={classes.divider} />
          </Box>
        </Box>
        <Box className={classes.assets}>
          {assets.map((asset) => (
            <Link
              to={`/asset-spots/${getAssetSlug(asset)}/`}
              style={{ textDecoration: "none" }}
              key={`${asset.asset_id}-${asset.name}`}
            >
              <Box height={50} display="flex" alignItems="center" alignContent="center">
                <Image
                  src={asset.thumb_url || ""}
                  style={{
                    height: "44px",
                    padding: "0",
                    paddingTop: "3px",
                    marginRight: theme.spacing(2),
                    width: "44px",
                    backgroundColor: "transparent",
                  }}
                  imageStyle={{
                    objectFit: "cover",
                    height: "44px",
                    width: "44px",
                    borderRadius: "5px",
                  }}
                />
                <Typography variant="body1">{asset.name}</Typography>
              </Box>
            </Link>
          ))}
        </Box>
        <Box className={classes.backBtn}>
          <Button variant="text" color="primary" onClick={onClose}>
            <Trans>Volver</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AssetsModalMobile;
