import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { makeStyles, Fade } from "@material-ui/core";
import Supercluster from "supercluster";
import config from "../../config";

import PopupDetails from "./popup-details";
import { filterByCity, popupOffsets } from "./utils";
import { getLanguageLocalStorage } from "../../utils";

mapboxgl.accessToken = config.MAPBOX_API_KEY;

const useStylesMapBox = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  mapContainer: {
    height: 500,
    width: "75%",
    "& .mapboxgl-popup-content": {
      padding: "10px 10px 5px",
    },
    "& .mapboxgl-popup-close-button": {
      fontSize: theme.spacing(2),
    },
    "& .mapboxgl-marker": {
      top: "-10px",
    },
    [theme.breakpoints.down("xs")]: {
      height: 400,
    },
  },
}));
const MapBox = ({ assets = [] }) => {
  const mapContainer = useRef(null);
  const mainContainer = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ offset: popupOffsets, closeOnClick: false }));
  const classes = useStylesMapBox();

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: config.HOME_MAP_STYLE,
      bounds: config.HOME_MAP_BOUNDS,
      dragPan: true,
    });

    map.setMinZoom(map.getZoom());

    // disable some interaction with the map
    map.doubleClickZoom.disable();
    map.dragRotate.disable();
    map.touchZoomRotate.disable();
    map.touchPitch.disable();

    map.loadImage(config.MAPBOX_MARKER, (error, image) => {
      if (error) throw error;
      map.addImage("custom-marker", image);

      map.on("load", () => {
        const language = getLanguageLocalStorage();
        map.setLayoutProperty("country-label", "text-field", ["get", `name_${language.languageType}`]);

        const assetsByCity = filterByCity(assets);

        const cluster = new Supercluster({
          radius: 20,
          maxZoom: 12,
        });

        cluster.load(
          Object.keys(assetsByCity).map((city) => ({
            type: "Feature",
            properties: { city },
            geometry: {
              type: "Point",
              coordinates: assetsByCity[city][0].coordinates,
            },
          })),
        );

        // Hacer zoom en el clúster
        map.on("click", "clusters", (e) => {
          const features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
          const clusterId = features[0].properties.cluster_id;
          map.getSource("assets").getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
        });

        // Crear capas de clústeres
        map.addSource("assets", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: Object.keys(assetsByCity).map((city) => ({
              type: "Feature",
              properties: { city },
              geometry: {
                type: "Point",
                coordinates: assetsByCity[city][0].coordinates,
              },
            })),
          },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 20,
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "assets",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": ["step", ["get", "point_count"], "#006a4d", 100, "#006a4d", 750, "#006a4d"],
            "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "assets",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": "#ffffff",
          },
        });

        // Agrega la capa de símbolo con la imagen personalizada
        map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "assets",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "custom-marker", // Nombre de la imagen cargada
            "icon-size": 0.15,
            "icon-allow-overlap": true,
          },
        });

        // Update the popup on hover
        map.on("mouseenter", "unclustered-point", (e) => {
          const { city } = e.features[0].properties; // Assuming you have a 'city' property in your GeoJSON features
          const popupNode = document.createElement("div");
          // Create a content
          ReactDOM.render(
            <PopupDetails city={city} assets={assetsByCity[city]} pathname={language.pathname} />,
            popupNode,
          );
          // Set popup on map
          popupRef.current.setLngLat(assetsByCity[city][0].coordinates).setDOMContent(popupNode).addTo(map);
        });

        mainContainer.current.addEventListener("mouseleave", () => {
          popupRef.current.remove();
        });
      });
    });

    // Clean up on unmount
    return () => map.remove();
  }, []);

  return (
    <Fade in timeout={1000}>
      <div ref={mainContainer} className={classes.mainContainer}>
        <div ref={mapContainer} className={classes.mapContainer} />
      </div>
    </Fade>
  );
};
export default MapBox;
