import React from "react";

export default function LampIcon({
  fill = "black",
  width = 56,
  height = 58,
  ...rest
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7424 27.1521C45.7424 17.5897 37.9624 9.81055 28.4 9.81055C18.8376 9.81055 11.0584 17.5897 11.0584 27.1521C11.0584 33.0393 14.0472 38.5849 18.8 41.7881V53.4282L24.2032 57.4001H32.808L37.2 53.3161V42.2649C42.4184 39.1161 45.7424 33.2985 45.7424 27.1521ZM34 47.0001H22V43.0001H34V47.0001ZM31.5504 54.2001H25.2536L22 51.8097V50.2001H34V51.9217L31.5504 54.2001ZM35.0656 39.8001H21.68C17.1648 37.3473 14.2592 32.4185 14.2592 27.1521C14.2592 19.3545 20.6032 13.0105 28.4008 13.0105C36.1984 13.0105 42.5432 19.3545 42.5432 27.1521C42.5424 32.3721 39.6152 37.3001 35.0656 39.8001Z"
        fill={fill}
      />
      <path d="M55.6 27H50.8V30.2H55.6V27Z" fill={fill} />
      <path d="M5.2 27H0.399994V30.2H5.2V27Z" fill={fill} />
      <path
        d="M43.1415 45.0489L46.7845 48.6919L49.0472 46.4292L45.4043 42.7862L43.1415 45.0489Z"
        fill={fill}
      />
      <path
        d="M13.66 11.0397L10.017 7.39673L7.75431 9.65945L11.3973 13.3024L13.66 11.0397Z"
        fill={fill}
      />
      <path d="M29.2 0.600098H26V6.2001H29.2V0.600098Z" fill={fill} />
      <path
        d="M7.7523 46.4287L10.015 48.6914L13.658 45.0484L11.3953 42.7857L7.7523 46.4287Z"
        fill={fill}
      />
      <path
        d="M43.1402 11.0409L45.4026 13.304L49.0466 9.66092L46.7842 7.39788L43.1402 11.0409Z"
        fill={fill}
      />
      <path d="M29.2 32.6001H26V35.8001H29.2V32.6001Z" fill={fill} />
      <path
        d="M32.4056 20.8408C31.9288 20.4784 31.3992 20.2152 30.8096 20.0488C30.2232 19.8824 29.64 19.8 29.068 19.8C27.9816 19.8 27.016 20.0568 26.172 20.5688C25.3264 21.0832 24.708 21.7984 24.3168 22.7192L27.0536 24.6648C27.1448 24.5592 27.2384 24.4272 27.3368 24.2696C27.4352 24.1112 27.5552 23.956 27.6992 23.8056C27.8424 23.6544 28.012 23.5264 28.2088 23.4216C28.4032 23.316 28.6384 23.2632 28.9096 23.2632C29.272 23.2632 29.5888 23.3656 29.86 23.5688C30.1328 23.772 30.268 24.0472 30.268 24.3952C30.268 24.7112 30.1536 25.0016 29.9272 25.2656C29.7024 25.5296 29.4336 25.7672 29.124 25.9784C28.8144 26.1896 28.5056 26.3704 28.196 26.5216C27.8864 26.6728 27.6488 26.7928 27.4832 26.8832C27.0768 27.0952 26.752 27.3288 26.5104 27.5848C26.2688 27.8424 26.0888 28.1088 25.968 28.388C25.8464 28.668 25.772 28.9576 25.7416 29.2672C25.7112 29.5768 25.696 30.2 25.696 30.2H29.2032C29.2032 30.2 29.2968 29.7504 29.4864 29.5704C29.6736 29.3888 29.916 29.2184 30.2104 29.052C30.5048 28.8864 30.8256 28.7208 31.172 28.556C31.5192 28.3896 31.8576 28.1944 32.1904 27.968C32.8688 27.516 33.34 26.9912 33.6048 26.396C33.8664 25.8 34 25.1392 34 24.416C34 23.5704 33.8528 22.8552 33.5584 22.2664C33.2632 21.6784 32.8808 21.2032 32.4056 20.8408Z"
        fill={fill}
      />
    </svg>
  );
}
