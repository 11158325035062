import React, { useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import Image from "material-ui-image";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import TextField from "@material-ui/core/TextField";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import NoSsr from "@material-ui/core/NoSsr";
import Checkbox from "@material-ui/core/Checkbox";
import { useNavigate } from "../../hooks/navigation";
import config from "../../config";
import { isMobile, getAssetSlug } from "../../utils";
import SearchIcon from "../../icons/search-icon";
import theme from "../../theme-client";
import { getUrlFromSpotSlug } from "../asset-spots";
import DateSelectModal from "../date-select-modal";
import { formatDateUtc, isDateValid } from "../../utils/dateUtils";

const useStyles = makeStyles((th) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [th.breakpoints.up("sm")]: {
      height: 215,
    },
    [th.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerTitle: {
    "& > h1": {
      color: "white",
      fontSize: 28,
    },
    [th.breakpoints.down("xs")]: {
      marginTop: th.spacing(6),
      padding: th.spacing(1),
      textAlign: "center",
      "& > h1": {
        fontSize: 25,
      },
    },
  },
  headerContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "999",
  },
  headerSearchBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    [th.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  searchBox: {
    width: "275px",
  },
  customInput: {
    backgroundColor: th.palette.white,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: "6px 4px 5px 4px",
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 11px)",
    },
    '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: "0px",
        padding: "6px 4px 5px 4px",
      },
    "& .MuiOutlinedInput-input": {
      color: th.palette.black,
      width: 325,
      [th.breakpoints.down("xs")]: {
        width: "100%",
      },
      transition: th.transitions.create(["border-color", "box-shadow"]),
      fontSize: 16,
    },
    "& input::placeholder": {
      color: th.palette.black,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  categoryInput: {
    backgroundColor: th.palette.white,
    '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: "0px",
      },
    "& .MuiOutlinedInput-input": {
      color: th.palette.black,
      width: "220px",

      [th.breakpoints.down("xs")]: {
        width: "100%",
      },
      transition: th.transitions.create(["border-color", "box-shadow"]),
      fontSize: 16,
    },
    "& input::placeholder": {
      color: th.palette.black,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  autoCompletePopper: {
    "& .MuiAutocomplete-root": {
      borderRadius: 0,
    },
  },
  searchBoxIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: th.palette.primary.main,
    width: "48px",
    height: "48px",
    cursor: "pointer",
    [th.breakpoints.down("xs")]: {
      marginTop: th.spacing(1),
      width: "100%",
    },
  },
  // TODO: Remove previous implementation
  dateContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    [th.breakpoints.down("xs")]: {
      marginTop: th.spacing(1),
      marginLeft: th.spacing(0),
    },
  },
  dateInputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: "5px",
    top: "15px",
    borderRadius: "50%",
    width: "22.85px",
    height: "22.85px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  longTermInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    "padding-right": "9px",
    height: "48px",
  },
  dateInput: {
    height: "48px",
    width: "215px",
    backgroundColor: th.palette.white,
    "& .MuiOutlinedInput-input": {
      color: th.palette.black,
      fontSize: 16,
      padding: "14.5px 14px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    [th.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const GenericOption = ({ name = "" }) => (
  <Box display="flex" flexDirection="row" alignItems="center" width="100%" height="44px">
    <Box>
      <Typography variant="body1">{name}</Typography>
    </Box>
  </Box>
);

const AssetOption = ({ name = "", image = "" }) => (
  <Box display="flex" flexDirection="row" alignItems="center" width="100%" height="44px">
    <Image
      disableSpinner
      src={image}
      style={{
        width: "48px",
        height: "48px",
        padding: 0,
      }}
      imageStyle={{
        objectFit: "cover",
        width: "100%",
        height: "100%",
        borderRadius: "5px",
      }}
    />
    <Box ml={2}>
      <Typography variant="body1">{name}</Typography>
    </Box>
  </Box>
);

const CustomPopper = (props) => (
  <Popper
    {...props}
    placement="bottom"
    modifiers={{
      flip: {
        enabled: false,
      },
    }}
  />
);

function Header({
  regions,
  assets,
  spots,
  onSelectRegion,
  onSelectAsset,
  // TODO: Remove previous implementation
  flagSeoOptions,
  flagLongTermBooking,
  searchAssetSpaces,
}) {
  const { navigate } = useNavigate();
  const classes = useStyles({ ...theme });
  const { i18n } = useLingui();
  const [region, setRegion] = useState(null);
  const [asset, setAsset] = useState(null);
  const [spot, setSpot] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openRegionSearchBar, setOpenRegionSearchBar] = useState(false);
  const [openAssetSearchBar, setOpenAssetSearchBar] = useState(false);
  const [openTypeSearchBar, setOpenTypeSearchBar] = useState(false);

  // TODO: Remove previous implementation
  const dateRef = useRef();
  const [isLongTermChecked, setLongTermChecked] = useState(false);
  const [dates, setDates] = useState({ start: "", end: "" });
  const [openDateModal, setOpenDateModal] = useState(false);

  const handleGoToAssetPage = () => {
    if (flagSeoOptions) {
      if (region && !asset && !spot) {
        navigate(`/region-assets/${region.id}`);
      } else if (asset && !spot) {
        navigate(`/asset-spots/${getAssetSlug(asset)}`);
      } else if (asset && spot) {
        navigate(getUrlFromSpotSlug(spot, asset, null, null, false));
      } else {
        setOpenError(true);
      }
    } else {
      // TODO: Remove previous implementation
      let params = "";
      if (isLongTermChecked) {
        params += `?start=${dates?.start}&long_term=true`;
      } else {
        params += `?start=${dates?.start}&end=${dates?.end}`;
      }
      navigate(`/asset-spots/${getAssetSlug(asset)}/${params}`);
      searchAssetSpaces(asset, dates.start, dates.end, isLongTermChecked);
    }
  };

  const handleOnInputChange = (itemSelected, inputName) => {
    switch (inputName) {
      case "region":
        onSelectRegion(itemSelected);
        setRegion(itemSelected);
        if (!itemSelected) {
          onSelectAsset(null);
          setAsset(null);
          setSpot(null);
        }
        break;
      case "asset":
        onSelectAsset(itemSelected);
        setAsset(itemSelected);
        if (!itemSelected) {
          setSpot(null);
        }
        break;
      case "spot":
        setSpot(itemSelected);
        break;
      default:
        onSelectRegion(null);
        onSelectAsset(null);
        setRegion(null);
        setAsset(null);
        setSpot(null);
        break;
    }
  };

  // TODO: Remove previous implementation
  const handleCheckboxChange = (event) => {
    setLongTermChecked(event.target.checked);
    setDates({
      start: "",
      end: "",
    });
  };

  // TODO: Remove previous implementation
  const handleChangeDates = (start, end) => {
    const isStartValid = isDateValid(start);

    if (isLongTermChecked) {
      setDates({
        start: isStartValid ? formatDateUtc(start) : "",
        end: "",
      });
    } else if (isDateValid(start) && isDateValid(end)) {
      setDates({
        start: formatDateUtc(start),
        end: formatDateUtc(end),
      });
    } else {
      setDates({
        start: "",
        end: "",
      });
    }
  };

  // TODO: Remove previous implementation
  const handleDisplayDate = () => {
    if (isLongTermChecked && dates.start) {
      return `${formatDateUtc(dates.start, "DD/MM/YY")}`;
    }

    if (!isLongTermChecked && dates.start && dates.end) {
      return `${formatDateUtc(dates.start, "DD/MM/YY")} - ${formatDateUtc(dates.end, "DD/MM/YY")}`;
    }
    return "";
  };

  return (
    <Box id="header" className={classes.header}>
      <NoSsr>
        {/* // TODO: Remove previous implementation */}
        <DateSelectModal
          open={openDateModal}
          onClose={() => setOpenDateModal(false)}
          onSelectDates={handleChangeDates}
          startDate={dates.start}
          endDate={dates.end}
          longTermBooking={isLongTermChecked}
          calendarProps={{
            isDouble: true,
            selectRange: !isLongTermChecked,
          }}
        />
        <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)}>
          <Alert onClose={() => setOpenError(false)} severity="info">
            {flagSeoOptions ? (
              <Trans>Por favor seleccione un centro comercial o una región.</Trans>
            ) : (
              // TODO: Remove previous implementation
              <Trans>Por favor seleccione un centro comercial.</Trans>
            )}
          </Alert>
        </Snackbar>
      </NoSsr>
      <Image
        disableSpinner
        src={config.HOME_HEADER_IMG_URL}
        style={{
          position: "absolute",
          width: "100%",
          maxHeight: 215,
          filter: "brightness(60%)",
          padding: 0,
        }}
        imageStyle={{
          objectFit: "cover",
          width: "100%",
          height: 215,
        }}
      />
      <Box className={classes.headerContent}>
        <Box className={classes.headerTitle}>
          <Typography variant="h1">
            <Trans>ENCUENTRA EL ESPACIO QUE NECESITAS</Trans>
          </Typography>
        </Box>
        <NoSsr>
          <Box className={classes.headerSearchBox} mt={2}>
            {flagSeoOptions && (
              <Box
                className={classes.searchBox}
                onClick={() => setOpenRegionSearchBar((open) => !open)}
                onBlur={() => setOpenRegionSearchBar(() => false)}
              >
                <Autocomplete
                  open={openRegionSearchBar}
                  value={region}
                  loading={regions.length === 0}
                  options={regions}
                  PopperComponent={CustomPopper}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props) => GenericOption({ name: props.name })}
                  onChange={(event, itemSelected) => handleOnInputChange(itemSelected, "region")}
                  popupIcon={<ArrowForwardIosIcon style={{ transform: "rotate(90deg)" }} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={i18n._(t`Seleccione una Región`)}
                      className={classes.customInput}
                    />
                  )}
                />
              </Box>
            )}

            <Box
              className={classes.searchBox}
              onClick={() => setOpenAssetSearchBar((open) => !open)}
              onBlur={() => setOpenAssetSearchBar(() => false)}
            >
              <Autocomplete
                open={openAssetSearchBar}
                value={asset}
                loading={assets.length === 0}
                options={assets}
                PopperComponent={CustomPopper}
                getOptionLabel={(option) => option.name}
                renderOption={(props) => AssetOption({ name: props.name, image: props.thumb_url })}
                onChange={(event, itemSelected) => handleOnInputChange(itemSelected, "asset")}
                popupIcon={<ArrowForwardIosIcon style={{ transform: "rotate(90deg)" }} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={i18n._(t`Centro Comercial`)}
                    className={classes.customInput}
                  />
                )}
              />
            </Box>

            {flagSeoOptions && (
              <Box
                className={classes.searchBox}
                onClick={() => asset && setOpenTypeSearchBar((open) => !open)}
                onBlur={() => setOpenTypeSearchBar(() => false)}
              >
                <Autocomplete
                  open={openTypeSearchBar}
                  value={spot}
                  disabled={!asset}
                  options={spots}
                  PopperComponent={CustomPopper}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props) => GenericOption({ name: props.name })}
                  onChange={(event, itemSelected) => handleOnInputChange(itemSelected, "spot")}
                  popupIcon={<ArrowForwardIosIcon style={{ transform: "rotate(90deg)" }} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={i18n._(t`Seleccione tipo de uso`)}
                      style={{
                        backgroundColor: !asset ? theme.palette.lightGray : theme.palette.white,
                      }}
                      className={classes.customInput}
                    />
                  )}
                />
              </Box>
            )}

            {/* TODO: Remove previous implementation */}
            {!flagSeoOptions && (
              <>
                <Box ref={dateRef} className={classes.dateContainer}>
                  <TextField
                    value={handleDisplayDate()}
                    variant="outlined"
                    onClick={() => setOpenDateModal(true)}
                    placeholder={isLongTermChecked ? i18n._(t`Ingreso`) : i18n._(t`Ingreso / Salida`)}
                    className={classes.dateInput}
                  />

                  <Box className={classes.dateInputContainer}>
                    <ArrowForwardIosIcon
                      style={{
                        width: "18.85px",
                        height: "18.85px",
                        color: "rgba(0, 0, 0, 0.54)",
                        transform: "rotate(90deg)",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenDateModal(true)}
                    />
                  </Box>
                </Box>
              </>
            )}

            {/* TODO: Remove previous implementation */}
            {!flagSeoOptions && flagLongTermBooking && (
              <Box className={classes.longTermInputContainer}>
                <Checkbox
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                  checked={isLongTermChecked}
                  onChange={handleCheckboxChange}
                  color="secondary"
                  inputProps={{ "aria-label": "checkbox" }}
                />
                <Typography variant="h6">
                  <Trans>Estadía prolongada</Trans>
                </Typography>
              </Box>
            )}

            <Box className={classes.searchBoxIcon} onClick={handleGoToAssetPage}>
              <SearchIcon fill="white" width="28px" height="24px" />
              {isMobile() && (
                <Box display="flex" alignItems="center" justifyContent="center" ml={2}>
                  <Typography variant="body1" style={{ color: theme.palette.white }}>
                    <Trans>Búsqueda</Trans>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </NoSsr>
      </Box>
    </Box>
  );
}

export default Header;
