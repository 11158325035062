import React, { useRef } from "react";
import { Box, Hidden } from "@material-ui/core";
import Image from "material-ui-image";
import theme from "../../theme-client";
import { makeStyles } from "@material-ui/core/styles";
import { getAssetSlugMap } from "./utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { limitStringChars } from "../../utils";

const useStylesPopup = makeStyles((theme) => ({
  title: {
    marginTop: 0,
    fontWeight: 600,
    fontFamily: theme.fontFamily,
    fontSize: "14px",
    textAlign: "left",
    marginBottom: 0,
  },
  line: {
    marginBottom: theme.spacing(1),
    content: `""`,
    height: 1,
    backgroundColor: "black",
  },
  linkSpot: {
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:focus-visible": {
      outline: "none",
    },
  },
  asset: {
    color: "#333333",
    fontFamily: theme.fontFamily,
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
  },
  containerDescription: {
    maxHeight: "200px",
    // overflowY: "auto",
    overflowY: "initial",
    overflowX: "hidden",
    marginBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "150px",
    },
  },
  description: {
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
  },
  iconMore: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      bottom: "-8px",
      left: "50%",
    },
  },
}));

const PopupDetails = ({ city, assets = [], pathname }) => {
  const classes = useStylesPopup(theme);
  const listInnerRef = useRef();
  const IconRef = useRef();
  const onScroll = () => {
    console.log("onScroll");
    if (listInnerRef.current && IconRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        IconRef.current.style.transform = "rotate(180deg)";
        IconRef.current.style.transition = "transform 150ms ease";
      } else {
        IconRef.current.style.transform = "rotate(0deg)";
      }
    }
  };
  return (
    <Box style={{ maxHeight: "261px", position: "relative" }}>
      <p className={classes.title} style={{ color: theme.palette.black }}>
        {city}
      </p>
      <Box className={classes.line}></Box>
      <Box className={classes.containerDescription} onScroll={onScroll} ref={listInnerRef}>
        {assets.map((asset, index) => (
          <a
            className={classes.linkSpot}
            href={`${pathname}asset-spots/${getAssetSlugMap(city, asset.name)}`}
            target="_blank"
            key={asset.asset_id}
          >
            <Box
              height={50}
              width={200}
              display="flex"
              alignItems="center"
              alignContent="center"
              className={classes.description}
            >
              <Box width={44} height={44}>
                <Image
                  disableSpinner
                  src={asset.thumb_url || ""}
                  style={{
                    height: "44px",
                    padding: "0",
                    paddingTop: "3px",
                    width: "44px",
                    backgroundColor: "transparent",
                  }}
                  imageStyle={{
                    objectFit: "cover",
                    height: "44px",
                    width: "44px",
                    borderRadius: "5px",
                  }}
                />
              </Box>
              <Box pl={3 / 2}>
                <p className={classes.asset} style={{ color: theme.palette.black, textAlign: "left" }}>
                  {limitStringChars(asset.name, 40)}
                </p>
              </Box>
            </Box>
          </a>
        ))}
      </Box>
      <Hidden mdUp>
        {assets.length > 3 && (
          <Box className={classes.iconMore}>
            <ExpandMoreIcon fontSize="small" ref={IconRef} />
          </Box>
        )}
      </Hidden>
    </Box>
  );
};
export default PopupDetails;
